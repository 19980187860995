import {Button, Card, CardActions, CardContent, CardHeader, Grid} from '@material-ui/core';

import {NewReleases} from '@material-ui/icons';
// import Comments from "components/Comments/Comments";
import moment from 'moment';
import React, {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {SettingsContext} from "../contexts/Settings";

moment.locale(window.language);

export default function Versions() {
  const intl = useIntl();
  const {changelog} = useContext(SettingsContext);
  const [versionIndex, setVersionIndex] = useState(0);
  if (!changelog[versionIndex]) return null;
  return (<Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={10} md={9} lg={7}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<NewReleases/>}
            title={changelog[versionIndex]?.title}
            subheader={<Grid container justifyContent="space-between"><Grid item>{intl.formatMessage({
              id: "changelog.version",
              defaultMessage: "Version: {version}"
            }, changelog[versionIndex] || {})}</Grid>
              <Grid
                item>{moment(changelog[versionIndex]?.released_at).format(intl.formatMessage({id: "common.date.format"}))}</Grid></Grid>}
          />
          <CardContent><Grid container spacing={1}>
            <Grid item xs={12}>{changelog[versionIndex]?.featured_image &&
              <img alt={"featured"} src={changelog[versionIndex].featured_image}
                   style={{margin: "8px 0", maxWidth: "50%", float: "right"}}/>}<span
              dangerouslySetInnerHTML={{__html: changelog[versionIndex]?.description}}></span></Grid>
            {changelog[versionIndex]?.images?.map((img, idx) =>
              <Grid item xs={12} key={"version-" + versionIndex + "-image-" + idx} style={{marginTop: 24}}>
                <div style={{
                  textTransform: "capitalize",
                  fontWeight: "bold"
                }}>{String(img).split('/').pop().split('.').shift().split('-').join(' ')}</div>
                <img style={{margin: 24, maxWidth: "100% !important"}} alt={"image-" + idx} src={img}/>
              </Grid>
            )}
          </Grid></CardContent>
          <CardActions>
            <Button variant="outlined"
                    onClick={() => setVersionIndex(versionIndex > 0 ? versionIndex - 1 : 0)}>{
              intl.formatMessage({
                id: "common.button.previous",
                defaultMessage: "Previous"
              })
            }</Button>
            <div style={{paddingTop: 8}}>{versionIndex + 1} / {changelog.length}</div>
            <Button variant="outlined"
                    onClick={() => setVersionIndex(versionIndex < changelog.length - 1 ? versionIndex + 1 : changelog.length - 1)}>{
              intl.formatMessage({
                id: "common.button.next",
                defaultMessage: "Next"
              })
            }</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
