import {Ballot, Print, Settings, WebAsset} from '@material-ui/icons';
import Entities from 'layouts/Entities';
import SinglePage from 'layouts/SinglePage';
import assetRoutes from 'routes/assets';
import {authUser} from 'contexts/Auth';
import Admin from './layouts/Admin';
import Auth from './layouts/Auth';
import Printout from './layouts/Printout';

export const rolePriorities = {
  developer: 5,
  site_manager: 10,
  admin: 25,
  can_users: 50,
  can_fields: 75,
  can_import_export: 100,
  can_assets: 125,
  can_data: 150,
  view_data: 175,
};

export const roles = (intl) => [
    {id: 5, name: intl.formatMessage({id: "roles.priority.5", defaultMessage: "Is system developer"})},
    {id: 10, name: intl.formatMessage({id: "roles.priority.10", defaultMessage: "Can edit sites"})},
    {id: 25, name: intl.formatMessage({id: "roles.priority.25", defaultMessage: "Can edit settings"})},
    {id: 50, name: intl.formatMessage({id: "roles.priority.50", defaultMessage: "Can edit users"})},
    {id: 75, name: intl.formatMessage({id: "roles.priority.75", defaultMessage: "Can edit fields"})},
    {id: 100, name: intl.formatMessage({id: "roles.priority.100", defaultMessage: "Can import/export"})},
    {id: 125, name: intl.formatMessage({id: "roles.priority.125", defaultMessage: "Can edit assets"})},
    {id: 150, name: intl.formatMessage({id: "roles.priority.150", defaultMessage: "Can edit data"})},
    {id: 175, name: intl.formatMessage({id: "roles.priority.175", defaultMessage: "Can view data"})},
  ];

export const features = {}
// const hasFeature = (feature) => this.features.findIndex(f => f.key === feature) !== -1;

export function getLayouts(intl) {
  if (authUser().roles.length === 1 && authUser().roles[0].key === "sites_manager") {
    return {
      admin: {
        title: intl.formatMessage({id: "layouts.admin", defaultMessage: "Administration"}),
        icon: Settings,
        authenticated: true,
        component: Admin,
        acl: rolePriorities.site_manager,
        features: [],
        path: "/admin",
        defaultRoute: "/admin/sites"
      },
      auth: {
        title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
        authenticated: false,
        component: Auth,
        acl: null,
        features: [],
        path: "",
        defaultRoute: "/login"
      },
    }
  }
  return {
    entities: {
      title: intl.formatMessage({id: "layouts.entities", defaultMessage: "Data"}),
      icon: Ballot,
      authenticated: true,
      component: Entities,
      acl: rolePriorities.view_data,
      features: [],
      path: "/data/:entityTypeID?",
      defaultRoute: "/data",
      contextQuery: "entityTypes(filter:{only_with_role_access:true,is_pinned_to_menu:true}){data{id title is_pinned_to_menu menu_category}}",
      contextResultTitles: (resp) => {
        let r = [];
        resp?.entityTypes?.data.forEach(e => {
          let i = r.findIndex(r => r.title === e.menu_category);
          if (i === -1)
            r.push({
              id: e.id,
              title: e.menu_category,
              children: [{id: e.id, title: e.title}],
            });
          else
            r[i].children.push({id: e.id, title: e.title});
        });
        return r;
      },
    },
    assets: {
      title: intl.formatMessage({id: "layouts.assets", defaultMessage: "Assets"}),
      icon: WebAsset,
      authenticated: true,
      component: SinglePage,
      acl: rolePriorities.can_assets,
      features: [],
      path: "/assets",
      routes: assetRoutes(intl),
    },
    admin: {
      title: intl.formatMessage({id: "layouts.admin", defaultMessage: "Administration"}),
      icon: Settings,
      authenticated: true,
      hidden: true,
      component: Admin,
      acl: rolePriorities.can_import_export,
      features: [],
      path: "/admin",
      defaultRoute: "/admin/",
    },
    printout: {
      title: intl.formatMessage({id: "layouts.printout", defaultMessage: "Printout"}),
      icon: Print,
      authenticated: true,
      hidden: true,
      component: Printout,
      acl: rolePriorities.view_data,
      features: [],
      path: "/printout/:id"
    },
    auth: {
      title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
      authenticated: false,
      component: Auth,
      acl: null,
      features: [],
      path: "",
      defaultRoute: "/login"
    },
  };
}

export const config = {
  brandLogo: require("assets/img/brand/logo-black-no-background.svg"),
  emailVerification: true,
  layoutNavigation: "horisontal",  // context = in the user profile context menu, horisontal = top navbar with no breadcrumb
  viewNavigation: "left",       // left = left vertical, top = top horisontal instead of breadcrumb
  auth: {
    register: false,
    rememberMe: false,
    passwordReset: true,
  },
  admin: {},
  sentryDsn: 'https://ad21f7e4983542dc9b5892dc7cafdaf5@o724124.ingest.sentry.io/5783333',
}

export const getLanguages = (intl) => [
  {name: intl.formatMessage({id: "languages.selector.Abkhazian"}), id: "ab"},
  {name: intl.formatMessage({id: "languages.selector.Afar"}), id: "aa"},
  {name: intl.formatMessage({id: "languages.selector.Afrikaans"}), id: "af"},
  {name: intl.formatMessage({id: "languages.selector.Albanian"}), id: "sq"},
  {name: intl.formatMessage({id: "languages.selector.Amharic"}), id: "am"},
  {name: intl.formatMessage({id: "languages.selector.Arabic"}), id: "ar"},
  {name: intl.formatMessage({id: "languages.selector.Aragonese"}), id: "an"},
  {name: intl.formatMessage({id: "languages.selector.Armenian"}), id: "hy"},
  {name: intl.formatMessage({id: "languages.selector.Assamese"}), id: "as"},
  {name: intl.formatMessage({id: "languages.selector.Aymara"}), id: "ay"},
  {name: intl.formatMessage({id: "languages.selector.Azerbaijani"}), id: "az"},
  {name: intl.formatMessage({id: "languages.selector.Bashkir"}), id: "ba"},
  {name: intl.formatMessage({id: "languages.selector.Basque"}), id: "eu"},
  {name: intl.formatMessage({id: "languages.selector.Bengali (Bangla)"}), id: "bn"},
  {name: intl.formatMessage({id: "languages.selector.Bhutani"}), id: "dz"},
  {name: intl.formatMessage({id: "languages.selector.Bihari"}), id: "bh"},
  {name: intl.formatMessage({id: "languages.selector.Bislama"}), id: "bi"},
  {name: intl.formatMessage({id: "languages.selector.Breton"}), id: "br"},
  {name: intl.formatMessage({id: "languages.selector.Bulgarian"}), id: "bg"},
  {name: intl.formatMessage({id: "languages.selector.Burmese"}), id: "my"},
  {name: intl.formatMessage({id: "languages.selector.Belarusian"}), id: "be"},
  {name: intl.formatMessage({id: "languages.selector.Cambodian"}), id: "km"},
  {name: intl.formatMessage({id: "languages.selector.Catalan"}), id: "ca"},
  {name: intl.formatMessage({id: "languages.selector.Chinese"}), id: "zh"},
  {name: intl.formatMessage({id: "languages.selector.Corsican"}), id: "co"},
  {name: intl.formatMessage({id: "languages.selector.Croatian"}), id: "hr"},
  {name: intl.formatMessage({id: "languages.selector.Czech"}), id: "cs"},
  {name: intl.formatMessage({id: "languages.selector.Danish"}), id: "da"},
  {name: intl.formatMessage({id: "languages.selector.Dutch"}), id: "nl"},
  {name: intl.formatMessage({id: "languages.selector.English"}), id: "en"},
  {name: intl.formatMessage({id: "languages.selector.Esperanto"}), id: "eo"},
  {name: intl.formatMessage({id: "languages.selector.Estonian"}), id: "et"},
  {name: intl.formatMessage({id: "languages.selector.Faroese"}), id: "fo"},
  {name: intl.formatMessage({id: "languages.selector.Farsi"}), id: "fa"},
  {name: intl.formatMessage({id: "languages.selector.Fiji"}), id: "fj"},
  {name: intl.formatMessage({id: "languages.selector.Finnish"}), id: "fi"},
  {name: intl.formatMessage({id: "languages.selector.French"}), id: "fr"},
  {name: intl.formatMessage({id: "languages.selector.Frisian"}), id: "fy"},
  {name: intl.formatMessage({id: "languages.selector.Galician"}), id: "gl"},
  {name: intl.formatMessage({id: "languages.selector.Gaelic"}), id: "gd"},
  {name: intl.formatMessage({id: "languages.selector.Georgian"}), id: "ka"},
  {name: intl.formatMessage({id: "languages.selector.German"}), id: "de"},
  {name: intl.formatMessage({id: "languages.selector.Greek"}), id: "el"},
  {name: intl.formatMessage({id: "languages.selector.Greenlandic"}), id: "kl"},
  {name: intl.formatMessage({id: "languages.selector.Guarani"}), id: "gn"},
  {name: intl.formatMessage({id: "languages.selector.Gujarati"}), id: "gu"},
  {name: intl.formatMessage({id: "languages.selector.Haitian Creole"}), id: "ht"},
  {name: intl.formatMessage({id: "languages.selector.Hausa"}), id: "ha"},
  {name: intl.formatMessage({id: "languages.selector.Hebrew"}), id: "he"},
  {name: intl.formatMessage({id: "languages.selector.Hindi"}), id: "hi"},
  {name: intl.formatMessage({id: "languages.selector.Hungarian"}), id: "hu"},
  {name: intl.formatMessage({id: "languages.selector.Icelandic"}), id: "is"},
  {name: intl.formatMessage({id: "languages.selector.Ido"}), id: "io"},
  {name: intl.formatMessage({id: "languages.selector.Indonesian"}), id: "id"},
  {name: intl.formatMessage({id: "languages.selector.Inuktitut"}), id: "iu"},
  {name: intl.formatMessage({id: "languages.selector.Inupiak"}), id: "ik"},
  {name: intl.formatMessage({id: "languages.selector.Irish"}), id: "ga"},
  {name: intl.formatMessage({id: "languages.selector.Italian"}), id: "it"},
  {name: intl.formatMessage({id: "languages.selector.Japanese"}), id: "ja"},
  {name: intl.formatMessage({id: "languages.selector.Javanese"}), id: "jv"},
  {name: intl.formatMessage({id: "languages.selector.Kannada"}), id: "kn"},
  {name: intl.formatMessage({id: "languages.selector.Kashmiri"}), id: "ks"},
  {name: intl.formatMessage({id: "languages.selector.Kazakh"}), id: "kk"},
  {name: intl.formatMessage({id: "languages.selector.Kinyarwanda (Rwanda)"}), id: "rw"},
  {name: intl.formatMessage({id: "languages.selector.Kirghiz"}), id: "ky"},
  {name: intl.formatMessage({id: "languages.selector.Kirundi (Rundi)"}), id: "rn"},
  {name: intl.formatMessage({id: "languages.selector.Korean"}), id: "ko"},
  {name: intl.formatMessage({id: "languages.selector.Kurdish"}), id: "ku"},
  {name: intl.formatMessage({id: "languages.selector.Laothian"}), id: "lo"},
  {name: intl.formatMessage({id: "languages.selector.Latin"}), id: "la"},
  {name: intl.formatMessage({id: "languages.selector.Latvian (Lettish)"}), id: "lv"},
  {name: intl.formatMessage({id: "languages.selector.Limburgish (Limburger)"}), id: "li"},
  {name: intl.formatMessage({id: "languages.selector.Lingala"}), id: "ln"},
  {name: intl.formatMessage({id: "languages.selector.Lithuanian"}), id: "lt"},
  {name: intl.formatMessage({id: "languages.selector.Macedonian"}), id: "mk"},
  {name: intl.formatMessage({id: "languages.selector.Malagasy"}), id: "mg"},
  {name: intl.formatMessage({id: "languages.selector.Malay"}), id: "ms"},
  {name: intl.formatMessage({id: "languages.selector.Malayalam"}), id: "ml"},
  {name: intl.formatMessage({id: "languages.selector.Maltese"}), id: "mt"},
  {name: intl.formatMessage({id: "languages.selector.Maori"}), id: "mi"},
  {name: intl.formatMessage({id: "languages.selector.Marathi"}), id: "mr"},
  {name: intl.formatMessage({id: "languages.selector.Mongolian"}), id: "mn"},
  {name: intl.formatMessage({id: "languages.selector.Nauru"}), id: "na"},
  {name: intl.formatMessage({id: "languages.selector.Nepali"}), id: "ne"},
  {name: intl.formatMessage({id: "languages.selector.Norwegian"}), id: "no"},
  {name: intl.formatMessage({id: "languages.selector.Occitan"}), id: "oc"},
  {name: intl.formatMessage({id: "languages.selector.Oriya"}), id: "or"},
  {name: intl.formatMessage({id: "languages.selector.Oromo (Afaan Oromo)"}), id: "om"},
  {name: intl.formatMessage({id: "languages.selector.Pashto (Pushto)"}), id: "ps"},
  {name: intl.formatMessage({id: "languages.selector.Polish"}), id: "pl"},
  {name: intl.formatMessage({id: "languages.selector.Portuguese"}), id: "pt"},
  {name: intl.formatMessage({id: "languages.selector.Punjabi"}), id: "pa"},
  {name: intl.formatMessage({id: "languages.selector.Quechua"}), id: "qu"},
  {name: intl.formatMessage({id: "languages.selector.Romanian"}), id: "ro"},
  {name: intl.formatMessage({id: "languages.selector.Russian"}), id: "ru"},
  {name: intl.formatMessage({id: "languages.selector.Samoan"}), id: "sm"},
  {name: intl.formatMessage({id: "languages.selector.Sangro"}), id: "sg"},
  {name: intl.formatMessage({id: "languages.selector.Sanskrit"}), id: "sa"},
  {name: intl.formatMessage({id: "languages.selector.Serbian"}), id: "sr"},
  {name: intl.formatMessage({id: "languages.selector.Serbo-Croatian"}), id: "sh"},
  {name: intl.formatMessage({id: "languages.selector.Sesotho"}), id: "st"},
  {name: intl.formatMessage({id: "languages.selector.Setswana"}), id: "tn"},
  {name: intl.formatMessage({id: "languages.selector.Shona"}), id: "sn"},
  {name: intl.formatMessage({id: "languages.selector.Sichuan Yi"}), id: "ii"},
  {name: intl.formatMessage({id: "languages.selector.Sindhi"}), id: "sd"},
  {name: intl.formatMessage({id: "languages.selector.Sinhalese"}), id: "si"},
  {name: intl.formatMessage({id: "languages.selector.Siswati"}), id: "ss"},
  {name: intl.formatMessage({id: "languages.selector.Slovak"}), id: "sk"},
  {name: intl.formatMessage({id: "languages.selector.Slovenian"}), id: "sl"},
  {name: intl.formatMessage({id: "languages.selector.Somali"}), id: "so"},
  {name: intl.formatMessage({id: "languages.selector.Spanish"}), id: "es"},
  {name: intl.formatMessage({id: "languages.selector.Sundanese"}), id: "su"},
  {name: intl.formatMessage({id: "languages.selector.Swahili (Kiswahili)"}), id: "sw"},
  {name: intl.formatMessage({id: "languages.selector.Swedish"}), id: "sv"},
  {name: intl.formatMessage({id: "languages.selector.Tagalog"}), id: "tl"},
  {name: intl.formatMessage({id: "languages.selector.Tajik"}), id: "tg"},
  {name: intl.formatMessage({id: "languages.selector.Tamil"}), id: "ta"},
  {name: intl.formatMessage({id: "languages.selector.Tatar"}), id: "tt"},
  {name: intl.formatMessage({id: "languages.selector.Telugu"}), id: "te"},
  {name: intl.formatMessage({id: "languages.selector.Thai"}), id: "th"},
  {name: intl.formatMessage({id: "languages.selector.Tibetan"}), id: "bo"},
  {name: intl.formatMessage({id: "languages.selector.Tigrinya"}), id: "ti"},
  {name: intl.formatMessage({id: "languages.selector.Tonga"}), id: "to"},
  {name: intl.formatMessage({id: "languages.selector.Tsonga"}), id: "ts"},
  {name: intl.formatMessage({id: "languages.selector.Turkish"}), id: "tr"},
  {name: intl.formatMessage({id: "languages.selector.Turkmen"}), id: "tk"},
  {name: intl.formatMessage({id: "languages.selector.Twi"}), id: "tw"},
  {name: intl.formatMessage({id: "languages.selector.Uighur"}), id: "ug"},
  {name: intl.formatMessage({id: "languages.selector.Ukrainian"}), id: "uk"},
  {name: intl.formatMessage({id: "languages.selector.Urdu"}), id: "ur"},
  {name: intl.formatMessage({id: "languages.selector.Uzbek"}), id: "uz"},
  {name: intl.formatMessage({id: "languages.selector.Vietnamese"}), id: "vi"},
  {name: intl.formatMessage({id: "languages.selector.Wallon"}), id: "wa"},
  {name: intl.formatMessage({id: "languages.selector.Welsh"}), id: "cy"},
  {name: intl.formatMessage({id: "languages.selector.Wolof"}), id: "wo"},
  {name: intl.formatMessage({id: "languages.selector.Xhosa"}), id: "xh"},
  {name: intl.formatMessage({id: "languages.selector.Yiddish"}), id: "yi"},
  {name: intl.formatMessage({id: "languages.selector.Yoruba"}), id: "yo"},
  {name: intl.formatMessage({id: "languages.selector.Zulu"}), id: "zu"}
];
