import React, {useContext} from "react";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {TextField} from "@material-ui/core";
import {getFieldLabel, RenderEditableField} from "./Field";
import RenderEntityValue from "../RenderEntityValue";

export default function TextareaField(
  {
    field,
    ...rest
  }) {
  const {language} = useContext(LanguageContext);
  const {getValuesOfField, setValuesOfField} = useContext(EntityContext);
  let value = {};
  if (field.uses_languages) {
    value = getValuesOfField(field.id)?.find(v => v.language_id === language.id) || {};
  } else {
    value = getValuesOfField(field.id)?.pop() || {};
  }
  return <RenderEditableField
    field={field}
    value={<RenderEntityValue field={field} entityValues={[value]}/>}
    minHeight={85}
    closingKeys={['Escape']}
  >
    <TextField
      {...rest}
      style={{flexGrow: 1}}
      fullWidth
      autoFocus
      multiline
      minRows={4}
      label={getFieldLabel(field, language)}
      value={value?.text || ""}
      onChange={e => setValuesOfField(field.id, [{
        ...value,
        text: e.target.value,
      }])}
    />
  </RenderEditableField>;
}
