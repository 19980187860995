import React, {useEffect, useState} from "react";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {useIntl} from "react-intl";
import {Dns} from "@material-ui/icons";
import {useNavigate, useParams} from "react-router-dom";
import EditForm from "../../../components/Form/EditForm";
import {graphQLApi, graphQLReduceFields} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";

export default function SubscriptionEdit() {
  const intl = useIntl();
  const params = useParams();
  const navigate = useNavigate();

  let id = Number(params.id);
  let siteId = Number(params.site_id);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    type_id: "",
    subscription_id: "",
    renewed_at: "",
  });
  const [types, setTypes] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const fields = [
    {
      field: "type_id",
      initial: "",
      label: intl.formatMessage({
        id: "subscriptions.edit.label.type",
        defaultMessage: "Type",
      }),
      titleField: "title",
      options: types,
      onChange: (value, data, setData) => setData({...data, type_id: value, subscription_id: ""})
    },
    {
      field: "subscription_id",
      initial: "",
      label: intl.formatMessage({
        id: "subscriptions.edit.label.subscription",
        defaultMessage: "Subscription",
      }),
      titleField: "title",
      options: subscriptions.filter(s => s.type_id === data?.type_id),
      disabled: d => !d.type_id,
    },
    {
      field: "renewed_at",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "subscriptions.edit.label.renewed_at",
        defaultMessage: "Renewed at",
      }),
      input: "datetime",
    },
  ];

  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };

  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors});
  useEffect(() => {
    setIsLoading(true);
    client.query("{subscriptions{id type type_id title}" + (id > 0 ? "siteSubscriptions(filter:{id:" + id + "}){data{id type_id subscription_id renewed_at}}" : "") + "}").then(resp => {
      setIsLoading(false);
      if (resp?.hasOwnProperty('siteSubscriptions')) {
        setData(resp.siteSubscriptions.data[0]);
      }
      if (resp?.hasOwnProperty('subscriptions')) {
        let tps = [];
        resp.subscriptions.forEach(s => {
          if (!tps.find(t => t.id === s.type_id)) {
            tps.push({name: s.type, id: s.type_id});
          }
        });
        setTypes(tps);
        setSubscriptions(resp.subscriptions.map(s => ({...s, id: s.id, name: s.title})));
      }
    })
  }, []);

  const save = (d) => {
    const v = {
      site_id: "ID",
      type_id: "Int",
      subscription_id: "Int",
      renewed_at: "String!",
    }
    d.site_id = siteId;
    if (id) {
      d.id = id;
      v.id = "ID!";
    }
    setIsLoading(true);
    client.mutation("siteSubscription", v, d, "id type_id subscription_id renewed_at").then(resp => {
      setIsLoading(false);
      if (resp?.hasOwnProperty('response')) {
        setData(resp.response);
        navigate(-1);
      }
    });
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<Dns/>}
            title={intl.formatMessage({id: "subscriptions.edit.heading"})}/>
          <CardContent>
            <EditForm
              validation={validation}
              data={data}
              setData={setData}
              fields={fields}
              save={save}
              isLoading={isLoading}
              back
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
