import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
    appBarRoot: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
      background: theme.palette.background.default,
        color: theme.palette.primary.text,
    },
    actionAppBarRoot: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        // paddingLeft: "250px",
        // backgroundColor: theme.palette.background.default,
        background: theme.palette.navbar.main,
        borderBottomColor: theme.palette.navbar.dark,
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        color: theme.palette.primary.text,
      top: 50,
    },
    toolbarRoot: {
        minHeight: 50,
        maxHeight: 50
    },
    brandTitle: {
        textTransform: "uppercase",
        margin: "0",
        color: theme.palette.adminNavbarSearch.main,
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    searchBox: {
        padding: ".25rem .25rem",
        borderColor: theme.palette.adminNavbarSearch.main,
        borderRadius: "2rem",
        border: "2px solid",
        backgroundColor: "initial",
        boxShadow: boxShadows.inputBoxShadow,
        transition: "box-shadow .15s ease",
    },
    searchIcon: {
        color: theme.palette.adminNavbarSearch.main,
        marginRight: "0.5rem",
        marginLeft: "1rem",
    },
    searchInput: {
        color: theme.palette.adminNavbarSearch.main,
        width: "270px",
        backgroundColor: "initial",
        border: 0,
        boxShadow: "none",
        padding: "0",
    },
    containerRoot: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: 0,
          paddingRight: 0,
        },
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    breadcrumb: {
        "&:hover": {
            cursor: "pointer"
        }
    },

    logoBox: {
        height: 50,
        width: 250,
        padding: 4,
        marginRight: 20,
    },
    logoClasses: {
        height: "100%",
        maxWidth: "100%",
        verticalAlign: "middle",
        borderStyle: "none",
        margin: "0 10px"
    },
    logoLinkClasses: {
        fontSize: "1.25rem",
        lineHeight: "inherit",
        whiteSpace: "nowrap",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        height: "100%"
    },
    logoLinkText: {
        color: theme.palette.primary.main,
        margin: "12px 0 0 0",
        fontWeight: "bold",
    },
});

export default componentStyles;
