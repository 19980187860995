import {Button, Link, Menu, MenuItem} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

import componentStyles from 'assets/theme/components/admin-navbar.js';
import Breadcrumb from 'components/Breadcrumb';
// @material-ui/icons components
// core components
import NavbarDropdown from 'components/Dropdowns/NavbarDropdown.js';
import {config, getLayouts} from 'config';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import React, {useContext, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import NavbarChanges from "../Dropdowns/NavbarChanges";
import NavbarJobs from "../Dropdowns/NavbarJobs";
import NavbarDemo from "../Dropdowns/NavbarDemo";
import {useLocation, useNavigate} from "react-router-dom";
import theme from "../../assets/theme/theme";
import {Warning} from "@material-ui/icons";
import {SettingsContext} from "../../contexts/Settings";

const useStyles = makeStyles(componentStyles);

export default function AdminNavbar({routes, logo}) {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const intl = useIntl();
  const {siteSubscriptions} = useContext(SettingsContext);

  const getLayoutLinks = () => {
    let links = [];
    const layouts = getLayouts(intl);
    for (let key in layouts) {
      if (layouts[key].authenticated && authUser().isAllowed(layouts[key].acl) && !layouts[key].hidden) {
        links.push(layouts[key]);
      }
    }
    return links;
  }

  let logoImage = (
    <img alt={logo.imgAlt} className={classes.logoClasses} src={logo.imgSrc}/>
  );

  let logoObject =
    logo && logo.innerLink ? (
      <Link href={logo.innerLink} className={classes.logoLinkClasses}>
        {logoImage}
      </Link>
    ) : logo && logo.outterLink ? (
      <a href={logo.outterLink} className={classes.logoLinkClasses}>
        {logoImage}
      </a>
    ) : null;

  const [usage, setUsage] = React.useState(null);
  const client = new graphQLApi(useAuthDispatch());
  const [openMenu, setOpenMenu] = React.useState();
  const [links, setLinks] = React.useState([]);

  const handleMenuOpen = (id, event) => {
    setOpenMenu({id: id, anchor: event.currentTarget});
  };

  const handleMenuClose = () => {
    setOpenMenu(null);
  };

  const checkUsage = () => {
    client.query('{subscriptionUsages{type title usage limit message}}').then((response) => {
      let clear = true;
      if (response?.subscriptionUsages?.length > 0) {
        response.subscriptionUsages?.forEach(subscription => {
          if (subscription.message && subscription.usage > 0) {
            setUsage(subscription);
            clear = false;
          }
        })
      }
      if (clear) {
        setUsage(null);
      }
    });
  }

  useEffect(() => {
    const layoutLinks = async () => {
      const links = getLayoutLinks().map(async (layout, key) => {
        if (layout.contextQuery && layout.contextResultTitles) {
          const r = await client.query('{' + layout.contextQuery + '}');
          const categories = layout.contextResultTitles(r);
          if (!categories || categories.length === 0) return null;
          return (
            <div key={"layouts-" + key}>
              {categories.map((category, k) => <div key={"layout-" + key + "-category-" + k}
                                                    style={{display: "inline-block"}}>
                <Button startIcon={<layout.icon/>} onClick={e => {
                  if (category.children.length === 1) navigate(layout.defaultRoute + '/' + category.id);
                  else handleMenuOpen(category.id, e);
                }}>{category.title}</Button>
                <Menu
                  anchorEl={openMenu?.anchor}
                  anchorOrigin={{vertical: "top", horizontal: "left"}}
                  keepMounted
                  transformOrigin={{vertical: "top", horizontal: "left"}}
                  open={openMenu?.id === category.id}
                  onClose={handleMenuClose}
                  style={{marginTop: 25}}
                >
                  {category.children?.map((subcat, key) => (
                    <Box
                      key={"admin-navbar-titles-" + key}
                      display="flex!important"
                      alignItems="center!important"
                      component={MenuItem}
                      onClick={() => {
                        handleMenuClose();
                        navigate(layout.defaultRoute + '/' + subcat.id);
                      }}
                    >
                      <span>{subcat.title}</span>
                    </Box>
                  ))}
                </Menu>
              </div>)}
            </div>
          );
        } else return (<Button startIcon={<layout.icon/>} key={"layouts-" + key}
                               onClick={() => navigate(layout.defaultRoute ? layout.defaultRoute : layout.path)}>{layout.title}</Button>);
      });
      const allLinks = await Promise.all(links);
      setLinks(allLinks);
    };
    layoutLinks();
  }, [openMenu]);

  useEffect(() => {
    if (config.layoutNavigation === "horisontal") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      document.getElementById('main-content').scrollTop = 0;
    }
    checkUsage();
  }, [location]);

  useEffect(() => {
    checkUsage();
    // const usageInterval = setInterval(() => {
    //   checkUsage();
    // }, 10000);
    // return () => {
    //   clearInterval(usageInterval);
    // }
  }, [siteSubscriptions]);

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        classes={{root: classes.appBarRoot}}
      >
        <Toolbar disableGutters classes={{root: classes.toolbarRoot}}>
          <Container
            maxWidth={false}
            component={Box}
            classes={{root: classes.containerRoot}}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box display="flex" alignItems="center" width="auto">
                <Box paddingBottom="1rem" className={classes.logoBox}>{logoObject}</Box>
                {config.layoutNavigation === "horisontal"
                  ? links
                  : <Breadcrumb routes={routes}/>}
              </Box>
              <Box display="flex" alignItems="center" width="auto">
                {usage && <Button
                  style={{
                    textTransform: "none",
                    backgroundColor: usage.usage > usage.limit
                      ? theme.palette.error.main
                      : theme.palette.warning.main,
                    color: usage.usage > usage.limit
                      ? theme.palette.error.contrastText
                      : theme.palette.warning.contrastText,
                  }}
                  startIcon={<Warning/>}
                  onClick={_ => navigate('/admin/billing')}
                >{
                  usage.message
                    .replace('{limit}', intl.formatNumber(usage.limit))
                    .replace('{usage}', intl.formatNumber(usage.usage))
                    .replace('{percentage}', intl.formatNumber(usage.usage / usage.limit * 100, {maximumFractionDigits: 0}) + '%')
                }</Button>}
                <NavbarDemo/>
                <NavbarChanges/>
                <NavbarJobs/>
                <NavbarDropdown/>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
