import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {Language} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import {useParams} from "react-router-dom";
import {getLanguages} from "../../../config";

export default function LanguageEdit(props) {
    const intl = useIntl();
  const params = useParams();

  let id = Number(params.id);

  const languages = getLanguages(intl);

    const fields = [
        {
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "languages.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            field: "country_code",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "languages.edit.label.country_code",
                defaultMessage: "Country"
            }),
            input: "country",
            inputCode: "Alpha-2"
        },
        {
            field: "locale",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "languages.edit.label.locale",
                defaultMessage: "Language"
            }),
            options: languages.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                      avatar={<Language/>}
                        title={intl.formatMessage({id: "languages.edit.heading"})}
                    />
                    <CardContent>
                        <GraphQLEditForm
                            id={id}
                            query={"languages"}
                            mutations={"language"}
                            fields={fields}
                            cols={3}
                            {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
