import {Card, CardContent, CardHeader, Grid, MenuItem, Switch, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {isAllowedBySubscription, useAuthDispatch} from "../../../contexts/Auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons/faCrown";
import theme from "../../../assets/theme/theme";
import Tooltip from "@material-ui/core/Tooltip";
import {graphQLApi} from "../../../services/GraphQLApi";
import CountryLanguage from "country-locale-map";
import {getLanguages} from "../../../config";
import {getName} from "i18n-iso-countries";

const ContainerCard = ({children}) => {
  return <Card style={{
    borderRadius: 0,
    borderTopLeftRadius: "0.725rem",
    borderBottomLeftRadius: "0.725rem",
    boxShadow: "none",
  }}>{children}</Card>
}

export default function Settings() {
  const intl = useIntl();

  const showFeatureCrown = (subscriptions) => {
    if (!isAllowedBySubscription(subscriptions))
      return <Tooltip
        title={intl.formatMessage({
          id: "subscriptions.features.not-included",
          defaultMessage: "Your subscription does not include this feature"
        })}
      ><FontAwesomeIcon
        icon={faCrown}
        color={theme.palette.warning.dark}
        style={{
          float: "right",
          margin: ".5rem 0.25rem"
        }}
      /></Tooltip>
  }

  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const initialSettings = {
    language: {data: ''},
    locale: {data: ''},
    country_code: {data: ''},
    language_selector: {data: false},
    revisions: {data: false},
    exports_token: {data: ''},
    exports_download: {data: false},
  };
  const [settings, setSettings] = useState(initialSettings);
  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    setIsLoading(true);
    client.query('{settings{ data {id key type data} }}').then(r => {
      if (r?.hasOwnProperty('settings')) {
        setSettings(c => {
          let cur = {...c};
          r.settings.data.forEach(s => {
            switch (s.type) {
              case 'json':
                cur[s.key] = {...s, data: JSON.parse(s.data)};
                break;
              case 'boolean':
                cur[s.key] = {...s, data: s.data === '1'};
                break;
              default:
              case 'string':
                cur[s.key] = s;
            }
          });
          return cur;
        });
      }
      setIsLoading(false);
    })
    setCountries(CountryLanguage.getAllCountries().map(country => ({
      ...country,
      name: getName(country.alpha2, window.language),
    })).sort((a, b) => (a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()))));
    return () => {
      setSettings(initialSettings);
    }
  }, []);

  const handleChange = (key, value) => {
    let s = {...settings};
    s[key].data = value
    setSettings(s);
    switch (s[key].type) {
      case 'json':
        value = JSON.stringify(value);
        break;
      case 'boolean':
        value = value ? '1' : '0';
        break;
      default:
      case 'string':
    }
    setIsLoading(true);
    client.mutate('($id:ID!,$data:String){settingUpdate(id:$id, data:$data){id}}', {
      id: s[key].id,
      data: value
    }).then(_ => {
      setIsLoading(false);
    });
  }

  return <>
    <ContainerCard>
      <CardHeader
        title={intl.formatMessage({id: "settings.header", defaultMessage: "Account settings"})}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1"><FormattedMessage id="settings.language.label"
                                                          defaultMessage="Default country and language"/></Typography>
            <Typography variant="caption"><FormattedMessage id="settings.language.help"
                                                            defaultMessage="This determines the language and region all data have if you do not add more languages"/></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={isLoading}
              fullWidth
              variant="outlined"
              select
              name="country_code"
              value={settings.country_code?.data}
              onChange={event => {
                setSettings(c => {
                  let country = CountryLanguage.getCountryByAlpha2(event.target.value);
                  let cur = {...c};
                  cur.country_code = {...cur.country_code, data: country.alpha2};
                  cur.locale = {...cur.locale, data: country.default_locale.split('_')[0]};
                  cur.language = {
                    ...cur.language,
                    data: getLanguages(intl).find(l => l.id === cur.locale.data)?.name || cur.locale.data
                  };
                  client.mutate('{settingUpdate(id:' + cur.country_code.id + ', data:"' + cur.country_code.data + '"){id}}');
                  client.mutate('{settingUpdate(id:' + cur.locale.id + ', data:"' + cur.locale.data + '"){id}}');
                  client.mutate('{settingUpdate(id:' + cur.language.id + ', data:"' + cur.language.data + '"){id}}');
                  return cur;
                })
              }}
            >{countries.map((country, key) => (
              <MenuItem
                key={"language-" + country.numeric + "-item-" + key}
                value={country.alpha2}
              >{country.emoji + ' ' + country.name}</MenuItem>))
            }</TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            {showFeatureCrown([94])}
            <Typography variant="body1"><FormattedMessage id="settings.revisions.label"
                                                          defaultMessage="Revisions"/></Typography>
            <Typography variant="caption"><FormattedMessage id="settings.revisions.help"
                                                            defaultMessage="When making changes to data, require a user to write a revision message and save the changes made in the revision."/></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Switch
              name="revisions"
              value={1}
              disabled={!isAllowedBySubscription([94]) || isLoading}
              checked={settings.revisions.data}
              onChange={event => handleChange('revisions', event.target.checked)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {showFeatureCrown([93, 94])}
            <Typography variant="body1"><FormattedMessage id="settings.exports_token.label"
                                                          defaultMessage="Downloads token"/></Typography>
            <Typography variant="caption"><FormattedMessage id="settings.exports_token.help"
                                                            defaultMessage="Token used for downloading exports/labels from the api directly '/api/exports?token=[exports_token]'."/></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled={!isAllowedBySubscription([93, 94]) || isLoading}
              name="exports_token"
              value={settings.exports_token.data}
              onChange={event => setSettings(c => {
                let cur = {...c};
                cur.exports_token.data = event.target.value;
                return cur;
              })}
              onBlur={event => handleChange('exports_token', event.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {showFeatureCrown([93, 94])}
            <Typography variant="body1"><FormattedMessage id="settings.exports_download.label"
                                                          defaultMessage="Download the eksported text files"/></Typography>
            <Typography variant="caption"><FormattedMessage id="settings.exports_download.help"
                                                            defaultMessage="Should users by default also download exports, when a token for using direct downloads has been set?"/></Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Switch
              disabled={!isAllowedBySubscription([93, 94]) || isLoading}
              name="exports_download"
              value={1}
              checked={settings.exports_download.data}
              onChange={event => handleChange('exports_download', event.target.checked)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </ContainerCard>
  </>
}
