import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';

import componentStyles from 'assets/theme/layouts/admin.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
// @material-ui/icons components
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import {authUser} from 'contexts/Auth';
import React from 'react';
import {useIntl} from 'react-intl';
import {Route, Routes} from 'react-router-dom';
import {config} from 'config';

const useStyles = makeStyles(componentStyles);

export default function SinglePage({routes}) {
  const intl = useIntl();
  const classes = useStyles();
  const brandText = intl.formatMessage({id: "navbar.brand_text"});
  const getRoutes = (routes, prefix = "") => routes.map((prop) => {
    let rs = [];
    if (prop.views) {
      rs = getRoutes(prop.views, prefix + prop.path);
    }
    rs.push(<Route
      path={!prop.shared ? prefix + prop.path : prop.path}
      element={<prop.component/>}
      exact={prop.exact}
    />);
    return rs;
  });

  return (
    <Box className={classes.mainContentNoSidebar}>
      <AdminNavbar routes={routes} logo={{
        innerLink: "/",
        imgSrc: (authUser().site && authUser().site.logo_uri)
          ? authUser().site.logo_uri
          : config.brandLogo.default,
        imgAlt: brandText,
      }}/>
      <Container
        maxWidth={false}
        component={Box}
        classes={{root: classes.containerRoot}}
        id='main-content'
      >
        <Container classes={{root: classes.mainRoot}}>
          <Routes>
            {getRoutes(routes)}
          </Routes>
        </Container>
        <AdminFooter width={"100%"}/>
      </Container>
    </Box>
  );
};
