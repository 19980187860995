const componentStyles = (theme) => ({
  mainContent: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 260,
      padding: 0,
    },
  },
  mainContentNoSidebar: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  containerRoot: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      margin: 0,
      marginTop: 50,
      width: "100%",
    },
  },
  mainRoot: {
    minHeight: "calc(100vh - 102px);",
    maxWidth: "100% !important",
    padding: 0,
    margin: 0,
  },
  dynamicMenuContainer: {
    margin: 0,
    paddingRight: 0,
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      marginLeft: 260,
    },
  }
});

export default componentStyles;
