import React, {useEffect, useRef, useState} from 'react';
import {Button, Grid, IconButton, LinearProgress, Snackbar, SnackbarContent} from "@material-ui/core";
import {useAuthDispatch} from "../../contexts/Auth";
import {graphQLApi} from "../../services/GraphQLApi";
import {Close} from "@material-ui/icons";
import {useIntl} from "react-intl";
import themeColors from "../../assets/theme/colors";
import Link from "@material-ui/core/Link";
import theme from "../../assets/theme/theme";


export default function JobsChecker() {
  const intl = useIntl();
  let checkedAtTimer = useRef(null)
  const [showImporting, setShowImporting] = useState(false);
  const [importKey, setImportKey] = useState(null);
  const [jobStarted, setJobStarted] = useState(false);
  const [jobCompleted, setJobCompleted] = useState(false);
  const [jobCancelled, setJobCancelled] = useState(false);
  const [jobFailed, setJobFailed] = useState('');
  const [progress, setProgress] = useState(null);
  const [total, setTotal] = useState(0);
  const [showExporting, setShowExporting] = useState(false);
  const [exportKey, setExportKey] = useState(null);
  const [exportJobStarted, setExportJobStarted] = useState(false);
  const [exportJobCompleted, setExportJobCompleted] = useState(false);
  const [exportJobCancelled, setExportJobCancelled] = useState(false);
  const [exportJobFailed, setExportJobFailed] = useState('');
  const [exportProgress, setExportProgress] = useState(null);
  const [exportTotal, setExportTotal] = useState(0);
  const [exportUrl, setExportUrl] = useState('');
  const client = new graphQLApi(useAuthDispatch());

  useEffect(() => {
    checkedAtTimer.current = setInterval(() => {
      const iKey = JSON.parse(localStorage.getItem('importKey'))
      if (iKey) {
        checkJob(iKey.key);
      }
      setImportKey(cur => {
        if (iKey) {
          if (cur !== iKey.key)
            return iKey.key;
          return cur;
        }
        return null;
      })

      const eKey = JSON.parse(localStorage.getItem('exportKey'))
      if (eKey) {
        checkExportJob(eKey.key);
      }
      setExportKey(cur => {
        if (eKey) {
          if (cur !== eKey.key)
            return eKey.key;
          return cur;
        }
        return null;
      })
    }, 2500);
    return () => {
      clearInterval(checkedAtTimer.current);
    };
  }, []);

  useEffect(() => {
    const iKey = JSON.parse(localStorage.getItem('importKey'));
    if (iKey) {
      setShowImporting(true)
      setJobCompleted(false)
      setJobStarted(false)
      setJobCancelled(false)
      setTotal(iKey.total)
      setJobFailed(null)
    } else {
      // setShowImporting(false)
      setProgress(null)
      // setTotal(0)
    }
  }, [importKey]);

  useEffect(() => {
    const eKey = JSON.parse(localStorage.getItem('exportKey'));
    if (eKey) {
      setShowExporting(true)
      setExportJobCompleted(false)
      setExportJobStarted(false)
      setExportJobCancelled(false)
      setExportTotal(eKey.total)
      setExportJobFailed(null)
    } else {
      // setShowExporting(false)
      setExportProgress(null)
      setExportTotal(0)
    }
  }, [exportKey]);


  const checkJob = (key) => {
    if (key) {
      client.mutate('($key:String!) {importProgress(key:$key)}', {key: key}).then(r => {
        const data = JSON.parse(r.importProgress);
        if (data?.error) {
          setJobFailed(data.error);
          localStorage.removeItem('importKey')
        } else {
          setJobFailed(null);
          if (data?.progress >= 100) {
            setJobCompleted(true)
            localStorage.removeItem('importKey')
          } else {
            setJobStarted(true)
            setProgress(data?.progress)
          }
        }
      });
    }
  }

  const checkExportJob = (key) => {
    client.mutate('($key:String!) {exportProgress(key:$key)}', {key: key}).then(r => {
      const data = JSON.parse(r.exportProgress);
      if (data?.error) {
        setExportJobFailed(data.error);
        localStorage.removeItem('exportKey')
      } else {
        setExportJobFailed(null);
        if (data?.progress >= 100 && data.url !== null) {
          setExportJobCompleted(true)
          localStorage.removeItem('exportKey')
          setExportUrl(data.url)
          if (data.url?.startsWith('https') && window.open(data.url, '_blank')) {
            setShowExporting(false);
          }
        } else {
          setExportJobStarted(true)
          setExportProgress(data?.progress)
        }
      }
    });
  }

  const handleImportCancel = () => {
    client.mutate('($importKey:String!) {importJobDelete(key:$importKey)}', {importKey: importKey}).then(r => {
      if (r) {
        localStorage.removeItem('importKey')
        setJobCancelled(true)
      }
    });
  }
  const handleExportCancel = () => {
    client.mutate('($exportKey:String!) {exportJobDelete(key:$exportKey)}', {exportKey: exportKey}).then(r => {
      if (r) {
        setExportJobCancelled(true)
        localStorage.removeItem('exportKey')
      }
    });
  }

  // console.log('Exporting:', showExporting, exportProgress, exportJobStarted, exportJobCompleted, exportJobCancelled, exportJobFailed);
  // console.log('Importing:', showImporting, progress, jobStarted, jobCompleted, jobCancelled, jobFailed);

  return (
    <Snackbar open={showImporting || showExporting} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
              color={themeColors.info.snackbar}>
      <SnackbarContent
        style={{padding:0, backgroundColor: "transparent", border: "none", borderRadius:"1rem",minWidth:"unset"}}
        message={
          <Grid container spacing={2} style={{flexFlow: "column"}}>
            {showImporting &&
              <Grid item container spacing={2} justifyContent="space-between"
                    style={{
                      marginTop: 0,
                      marginLeft: 0,
                      color: jobFailed ? theme.palette.error.contrastText : jobCompleted ? theme.palette.success.contrastText : theme.palette.text.primary,
                      backgroundColor: jobFailed ? theme.palette.error.main : jobCompleted ? theme.palette.success.light : theme.palette.info.light,
                      borderRadius: '1rem',
                      alignContent: 'center',
                    }}>
                <Grid item style={{
                  paddingTop: 12,
                }}>
                  {
                    jobFailed ?
                      intl.formatMessage({
                        id: "jobs_checker.import.failed_message",
                        defaultMessage: "Import failed with message: {message}"
                      }, {message: jobFailed})
                      : jobCancelled ?
                        intl.formatMessage({
                          id: "jobs_checker.import.cancel_message",
                          defaultMessage: "Import has been cancelled"
                        })
                        :
                        jobCompleted ?
                          intl.formatMessage({
                            id: "jobs_checker.import.success_message",
                            defaultMessage: "{total} entities have been imported"
                          }, {total: total})
                          : jobStarted ?
                            <>
                              {intl.formatMessage({
                                id: "jobs_checker.import.progress_message",
                                defaultMessage: "Importing {progress} of {total} entities..."
                              }, {progress: progress + '%', total: total})}
                              <LinearProgress style={{marginBottom:0}} variant="determinate" value={progress}/>
                            </>
                            :
                            intl.formatMessage({
                              id: "jobs_checker.import.waiting_message",
                              defaultMessage: "Waiting for import to start"
                            })
                  }
                </Grid>

                {(!jobCompleted && !jobCancelled && !jobFailed) &&
                  <Grid item>
                    <Button
                      style={{
                        color: exportJobFailed ? theme.palette.error.contrastText : theme.palette.text.primary,
                        borderColor: exportJobFailed ? theme.palette.error.contrastText : theme.palette.text.primary,
                      }}
                      onClick={() => {
                        handleImportCancel()
                      }} size="small" variant="outlined">{intl.formatMessage({
                      id: "common.button.cancel",
                      defaultMessage: "Cancel"
                    })}</Button>
                  </Grid>
                }
                {(jobCompleted || jobCancelled || jobFailed) &&
                  <Grid item>
                    <IconButton
                      size="small"
                      title={intl.formatMessage({id: 'jobs_checker.completed_job.dismiss', defaultMessage: "Dismiss"})}
                      onClick={() => {
                        setShowImporting(false)
                      }}
                    ><Close/></IconButton>
                  </Grid>
                }</Grid>
            }
            {showExporting &&
              <Grid item container spacing={2} justifyContent="space-between"
                    style={{
                      marginTop: 0,
                      marginLeft: 0,
                      color: exportJobFailed ? theme.palette.error.contrastText : exportJobCompleted ? theme.palette.success.contrastText : theme.palette.info.contrastText,
                      backgroundColor: exportJobFailed ? theme.palette.error.main : exportJobCompleted ? theme.palette.success.light : theme.palette.info.light,
                      borderRadius: '1rem',
                      alignContent: 'center',
                    }}>
                <Grid item style={{
                  paddingTop: exportJobStarted && !exportJobCancelled && !exportJobCompleted ? 8 : 12,
                }}>
                  {
                    exportJobFailed ?
                      intl.formatMessage({
                        id: "jobs_checker.export.failure_message",
                        defaultMessage: "Export failed with message: {message}"
                      }, {message: exportJobFailed})
                      : exportJobCancelled ?
                        intl.formatMessage({
                          id: "jobs_checker.export.cancel_message",
                          defaultMessage: "Export has been cancelled"
                        })
                        :
                        exportJobCompleted ? <>
                            <Link color="inherit" href={exportUrl} target="_blank">{intl.formatMessage({
                              id: "jobs_checker.export.download",
                              defaultMessage: "Download the exported file"
                            })}</Link>
                          </>
                          :
                          exportJobStarted ?
                            <>
                              {intl.formatMessage({
                                id: "jobs_checker.export.progress_message",
                                defaultMessage: "Exporting {progress} of {total} entities..."
                              }, {progress: exportProgress + '%', total: exportTotal})}
                              <LinearProgress style={{marginBottom:0}} variant="determinate" value={exportProgress}/>
                            </>
                            :
                            intl.formatMessage({
                              id: "jobs_checker.export.waiting_message",
                              defaultMessage: "Waiting for export to start"
                            })
                  }
                </Grid>
                {(!exportJobCompleted && !exportJobCancelled && !exportJobFailed) &&
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleExportCancel()
                      }} size="small" variant="outlined">{intl.formatMessage({
                      id: "common.button.cancel",
                      defaultMessage: "Cancel"
                    })}</Button>
                  </Grid>
                }
                {(exportJobCompleted || exportJobCancelled || exportJobFailed) &&
                  <Grid item>
                    <IconButton
                      style={{
                        color: exportJobFailed ? theme.palette.error.contrastText : theme.palette.text.primary,
                      }}
                      size="small"
                      title={intl.formatMessage({id: 'jobs_checker.completed_job.dismiss', defaultMessage: "Dismiss"})}
                      onClick={() => {
                        setShowExporting(false)
                      }}
                    ><Close/></IconButton>
                  </Grid>
                }
              </Grid>
            }
          </Grid>
        }
      /></Snackbar>)
}
