const themeColors = {
  white: {
    main: "#FFFFFF",
  },
  black: {
    light: "#0c121f",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    50: "#e2e7f2",
    100: "#cacfd9",
    200: "#b3b7bf",
    300: "#9b9ea6",
    400: "#83868c",
    500: "#6b6e73",
    600: "#535559",
    700: "#3d3e41",
    800: "#252628",
    900: "#0d0e0e",
  },
  primary: {
    main: "#1f76c2",          // --
    light: "#2f95e1",         // -200
    dark: "#0a4891",          // +200
    snackbar: "#bbddf5",      // 100
    badgeBg: "#e3f2fb",       // 50
    badgeBgHover: "#4ba4e5",  // 400
    badge: "#1865b0",         // 800
    text: "#323338",           // ??
    button: "#0073ea",
  },
  secondary: {
    main: "#d23557",
    light: "#e23a5a",
    dark: "#822348",
    snackbar: "#f8bdcb",
    badgeBg: "#fce5ea",
    badgeBgHover: "#e74e70",
    badge: "#bc3053",
    text: "#f3f3f3",
    button: "#e74e70",
  },
  warning: {
    light: "#ffd600",
    main: "#febc00",
    snackbar: "#fff394",
    badgeBg: "#fffde5",
    badgeBgHover: "#fce400",
    badge: "#febc00",
  },
  error: {
    light: "#e57373",
    main: "#f44336",
    dark: "#d32f2f",
    snackbar: "#e57373",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e57373",
    badge: "#f44336",
    dialogNotification: "#f44336",
  },
  info: {
    main: "#11cdef",
    snackbar: "#37d5f2",
    badgeBg: "#aaedf9",
    badgeBgHover: "#0c9cb7",
    badge: "#03acca",
  },
  background: {
    default: "#ECEFF8",
  },
  text: {
    primary: "#323338",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#172b4d",
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBg: "#4172c6",
    badgeBgHover: "#09111e",
  },
  success: {
    main: "#2dce89",
    snackbar: "#4fd69c",
    badgeBg: "#b0eed3",
    badgeBgHover: "#229c68",
    badge: "#1aae6f",
  },
};

export default themeColors;
