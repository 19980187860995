import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import {authHeader, authResend, authSignOut, authUser, authVerify, getApiDomain, useAuthDispatch} from "contexts/Auth";
import {Button, CardHeader, CircularProgress, Typography} from "@material-ui/core";
import {useIntl} from "react-intl";

const useStyles = makeStyles(componentStyles);

function Verify() {
  const intl = useIntl();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const token = authHeader();

  const [resent, setResent] = React.useState(false);
  const [expired, setExpired] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [domainCheck, setDomainCheck] = React.useState(null);

  const gotoLogin = () => {
    navigate('/login?redirectTo=' + encodeURIComponent(location.pathname + location.search));
  }

  if (!token) gotoLogin();

  const getValidation = (data, status) => {
    console.log('getValidation', status, data);
    if (status === 403 || status === undefined) {
      setExpired(true);
    } else {
      console.log('Signing out...', status, data);
      authSignOut(dispatch);
      navigate('/');
    }
  };

  React.useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    if (parseInt(id) !== authUser().id) {
      authSignOut(dispatch);
      gotoLogin();
    }
    const hash = params.get("hash");
    const expires = params.get("expires");
    const expiresDate = new Date(expires * 1000);
    const isExpired = new Date() > expiresDate;
    setExpired(isExpired);
    const signature = params.get("signature");
    console.log(id, hash, expires, signature, expiresDate, !id || !hash || !expires || !signature, isExpired, token);
    if (!id || !hash || !expires || !signature) {
      console.log('Redirecting...');
      navigate('/');
    } else if (!isExpired && token) {
      console.log('Verifying...');
      setIsLoading(true);
      setExpired(false);
      authVerify(dispatch, setIsLoading, id, hash, expires, signature, token, getValidation);
    }
  }, [location]);

  React.useEffect(() => {
    if (domainCheck === null) {
      fetch(getApiDomain() + "/", {}).then((response) => {
        if (response.status === 200) {
          return response.text().then((text) => {
            if (text.indexOf(process.env.REACT_APP_NAME) !== -1 &&
              text.indexOf("OpenSUN") !== -1) {
              setDomainCheck(true);
            } else {
              setDomainCheck(false);
              console.error("Response from api check did not validate", text);
            }
          });
        } else {
          setDomainCheck(false);
        }
      }).catch(reason => {
        console.error('Failed to validate the API domain', getApiDomain() + "/", reason);
        setDomainCheck(false);
      });
    }
  });

  const resend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    authResend(dispatch, setIsLoading, authUser().email, getValidation);
    setResent(true);
  };

  return <Grid container justifyContent="center">
    <Grid item xs={12} lg={5} md={7}>
      <Card classes={{root: classes.cardRoot}}>
        <CardHeader title={intl.formatMessage({id: "verify.email.heading", defaultMessage: "E-mail verification"})}
                    classes={{root: classes.cardRoot, title: classes.cardTitle}}/>
        <CardContent classes={{root: classes.cardContent}}>
          <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
            {isLoading ? <CircularProgress/> : expired ? (<>
                <Typography variant="h3">{intl.formatMessage({
                  id: "verify.email.link-is-expired",
                  defaultMessage: "The link you have used has expired, please generate a new link by clicking the button here."
                })}</Typography>
                <br/>
                <Button color="primary" variant="contained" onClick={resend}>{
                  intl.formatMessage({id: "verify.email.button.resend", defaultMessage: "Resend verification e-mail"})
                }</Button>
              </>)
              : resent ?
                <Typography>{intl.formatMessage({
                  id: "verify.email.resent",
                  defaultMessage: "A new verification e-mail link has been sent to you."
                })}</Typography>
                : <><Typography variant="h3">{intl.formatMessage({
                  id: "verify.email.verified",
                  defaultMessage: "Your email has been verified!"
                })}</Typography>
                  <Button color="primary" variant="contained" onClick={_ => navigate('/', {replace: true})}>{
                    intl.formatMessage({id: "common.button.continue", defaultMessage: "Continue"})
                  }</Button>
                </>
            }
          </Box>
        </CardContent>
      </Card>
    </Grid>
  </Grid>;
}

export default Verify;
