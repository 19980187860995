import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'assets/theme/theme';
import ReleaseVersionChecker from 'components/ReleaseVersionChecker';
import React from 'react';
import Layouts from './Layouts';
import JobsChecker from "../components/JobsChecker";

export const tagManagerArgs = {
  gtmId: 'G-61LVLSKQKX'
}

export const App = () => {
  window.dataLayer.push({
    event: 'pageview'
  });
  return <ThemeProvider theme={theme}>
    <ReleaseVersionChecker/>
    <JobsChecker/>
    <CssBaseline/>
    <Layouts/>
  </ThemeProvider>
};
export default App;
