import React from "react";
import {useLocation} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
// core components
import componentStyles from "assets/theme/views/auth/login.js";
import {authReset, authSignOut, getApiDomain, useAuthDispatch} from "contexts/Auth";
import {CardActions, CardHeader, CircularProgress, FormHelperText, InputAdornment, TextField} from "@material-ui/core";
import {Email, VpnKey} from "@material-ui/icons";
import {useIntl} from "react-intl";

const useStyles = makeStyles(componentStyles);

function Reset() {
  const intl = useIntl();
  const dispatch = useAuthDispatch();

  const location = useLocation();
  const search = location.search;
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get('token');
  let initialEmail = "";
  if (urlParams.get('email')) initialEmail = urlParams.get('email');

  const [email, setEmail] = React.useState(initialEmail);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);

  const [domainCheck, setDomainCheck] = React.useState(null);

  React.useEffect(() => {
    if (domainCheck === null) {
      fetch(getApiDomain() + "/", {}).then((response) => {
        if (response.status === 200) {
          return response.text().then((text) => {
            if (text.indexOf(process.env.REACT_APP_NAME) !== -1 &&
              text.indexOf("OpenSUN") !== -1) {
              setDomainCheck(true);
            } else {
              setDomainCheck(false);
              console.error("Response from api check did not validate", text);
            }
          });
        } else {
          setDomainCheck(false);
        }
      }).catch(reason => {
        console.error('Failed to validate the API domain', getApiDomain() + "/", reason);
        setDomainCheck(false);
      });
    }
  });

  const getValidation = (apiValidation) => {
    if (apiValidation.message === "Unauthorized") {
      authSignOut(dispatch);
    }
  };

  const performVerify = (e) => {
    e.preventDefault();
    authReset(dispatch, setIsLoading, token, email, password, confirmPassword, getValidation);
  };

  const matchPasswords = () => {
    return confirmPassword.trim().length > 0 && password !== confirmPassword
  }
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} lg={5} md={7}>
        <Card classes={{root: classes.cardRoot}}>
          <CardHeader title={intl.formatMessage({id: "auth.reset_password", defaultMessage: "Reset password"})}
                      classes={{title: classes.cardTitle}} style={{textAlign: "center"}}/>
          <CardContent classes={{root: classes.cardContent}} component="form" onSubmit={performVerify}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Email/>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoComplete="new-password"
                  type="password"
                  label="New password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <VpnKey/>
                    </InputAdornment>
                  }
                  error={matchPasswords()}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoComplete="new-password"
                  type="password"
                  label="Confirm password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <VpnKey/>
                    </InputAdornment>
                  }
                  error={matchPasswords()}
                />
              </Grid>
              {matchPasswords() && (
                <Grid item xs={12}>
                  <FormHelperText style={{textAlign: "center"}}>{intl.formatMessage({
                    id: "auth.reset_password_match",
                    defaultMessage: "Passwords do not match!"
                  })}</FormHelperText>
                </Grid>
              )}
              {(password.trim().length && !matchPasswords() && password.trim().length < 8) ?
                <Grid item xs={12}>
                  <FormHelperText style={{textAlign: "center"}}>{intl.formatMessage({
                    id: "auth.reset_password_length",
                    defaultMessage: "Passwords is too short!"
                  })}</FormHelperText>
                </Grid> : null
              }

              <Grid item>
                {isLoading ?
                  <CircularProgress/>
                  :
                  <Button
                    disabled={!(token && email.trim().length > 0 && email.includes("@") && password.trim().length >= 8 && password === confirmPassword)}
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={performVerify}>{
                    intl.formatMessage({id: "common.button.save", defaultMessage: "Save"})
                  }</Button>
                }
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Link
              href="/login"
              className={classes.footerLinks}
            >
              {intl.formatMessage({id: "auth.back_to_login", defaultMessage: "Back to login"})}
            </Link>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
}

export default Reset;
