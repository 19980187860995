import React, {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {SettingsContext} from "../../contexts/Settings";

export default function NavbarChanges() {
  const intl = useIntl();

  const {changelog, settings, setUserSetting} = useContext(SettingsContext);
  const [showVersion, setShowVersion] = useState(0);

  function isLastVersionNewest() {
    if (!settings.user) return false;
    let i = changelog.findIndex(v => v.version === settings.user?.last_version);
    return (changelog.length - 1 > i);
  }

  function getVersions() {
    if (!settings.user) return [];
    let i = changelog.findIndex(v => v.version === settings.user?.last_version);
    return changelog.slice(i + 1);
  }
  const totalVersions = getVersions().length;

  return isLastVersionNewest() && <Dialog open={true} maxWidth="lg">
    <DialogTitle disableTypography style={{fontSize:"larger", fontWeight:"bold"}}>{intl.formatMessage({id:"versions.dialog.title", defaultMessage:"There is something we want to tell you"})}</DialogTitle>
    <DialogContent>{getVersions().reverse().map((v, vi) => <Grid container key={"versions-"+vi} style={{display: vi !== showVersion ? "none" : "", gap:12}}>
      <Grid item xs={12}>
        <div style={{float:"right"}}>{intl.formatMessage({id:"versions.version", defaultMessage:"Version: {version}"}, v)}</div>
        <strong>{v?.title}</strong>
      </Grid>
      <Grid item xs={12}>{v?.featured_image &&
        <img alt={"featured"} src={v.featured_image} style={{margin: "8px 0", maxWidth: "50%", float: "right"}}/>}<span
        dangerouslySetInnerHTML={{__html: v?.description}}></span></Grid>
      {v.images?.map((img, idx) =>
        <Grid item xs={12} key={"version-"+vi+"-image-"+idx} style={{marginTop:24}}>
          <div style={{
            textTransform: "capitalize",
            fontWeight: "bold"
          }}>{String(img).split('/').pop().split('.').shift().split('-').join(' ')}</div>
          <img alt={"image-" + idx} src={img} style={{margin: "8px 0", maxWidth: "100%"}}/>
        </Grid>
      )}
    </Grid>)}</DialogContent>
    <DialogActions style={{display:"flex",justifyContent:"space-between"}}>
      {totalVersions > 1 ? <Grid container item style={{gap: 12}}>
        <Button variant="outlined"
                onClick={() => setShowVersion(showVersion > 0 ? showVersion - 1 : 0)}>{intl.formatMessage({
          id: "common.button.previous",
          defaultMessage: "Previous"
        })}</Button>
        <div style={{paddingTop: 8}}>{showVersion + 1} / {totalVersions}</div>
        <Button variant="outlined"
                onClick={() => setShowVersion(showVersion < totalVersions - 1 ? showVersion + 1 : totalVersions - 1)}>{intl.formatMessage({
          id: "common.button.next",
          defaultMessage: "Next"
        })}</Button>
      </Grid> : <div/>}
      <Button variant="contained" color="primary"
              onClick={() => setUserSetting('last_version', [...changelog].pop().version)}>{intl.formatMessage({id: "common.button.ok"})}</Button>
    </DialogActions>
  </Dialog>;
}
