import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import {authResetPassword, getApiDomain, useAuthDispatch} from "contexts/Auth";
import {CardActions, CardHeader, CircularProgress, Link, TextField} from "@material-ui/core";
import {useIntl} from "react-intl";

const useStyles = makeStyles(componentStyles);

function ForgotPassword() {
    const [email, setEmail] = React.useState("");
    const [sent, setSent] = React.useState(false);

    const intl = useIntl();
    const dispatch = useAuthDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const [domainCheck, setDomainCheck] = React.useState(null);

    const validationFields = {errors: {}, message: ""};
    const [validation, setValidation] = React.useState(validationFields);

    React.useEffect(() => {
        if (domainCheck === null) {
            fetch(getApiDomain() + "/", {}).then((response) => {
                if (response.status === 200) {
                    return response.text().then((text) => {
                        if (text.indexOf(process.env.REACT_APP_NAME) !== -1 &&
                            text.indexOf("OpenSUN") !== -1) {
                            setDomainCheck(true);
                        } else {
                            setDomainCheck(false);
                            console.error("Response from api check did not validate", text);
                        }
                    });
                } else {
                    setDomainCheck(false);
                }
            }).catch(reason => {
                console.error('Failed to validate the API domain', getApiDomain() + "/", reason);
                setDomainCheck(false);
            });
        }
    });

    const sendReset = (e) => {
        e.preventDefault();
      authResetPassword(dispatch, setIsLoading, email, setValidation).then(r => {
          console.log(!r.errors, r);
          if (!r.errors) {
            setValidation({...validationFields, message: r.message});
            setSent(true);
          }
        });
    };

    const classes = useStyles();
    return (
        <>
            <Grid item xs={12} lg={5} md={7}>
                    <Card classes={{root: classes.cardRoot}}>
                      <CardHeader
                        title={intl.formatMessage({id: "auth.reset_password", defaultMessage: "Reset password"})}
                        classes={{title: classes.cardTitle}} style={{textAlign: "center"}}/>
                      <CardContent classes={{root: classes.cardContent}} style={{padding: "20px 1.5rem"}}
                                   component="form" onSubmit={sendReset}>
                                <div style={{marginBottom:20,fontWeight:"bold"}}>{validation.message}</div>
                                {!sent && (
                                    <>
                                        <TextField
                                            label={intl.formatMessage({id: "user.edit.label.email", defaultMessage:"Enter your email address"})}
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            fullWidth
                                            error={validation?.errors?.email?.length > 0}
                                            helperText={validation?.errors?.email?.join(". ")}
                                        />
                                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                            {isLoading ?
                                                <CircularProgress/>
                                                :
                                                // <Button disabled={!(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email))} color="primary" variant="contained" onClick={sendReset}>
                                                <Button color="primary" variant="contained" onClick={sendReset}>
                                                    {intl.formatMessage({id:"auth.send_password_reset_link", defaultMessage:"Send password reset link"})}
                                                </Button>
                                            }
                                        </Box>
                                    </>
                                )}
                        </CardContent>
                      <CardActions>
                        <Link
                          href="/login"
                          className={classes.footerLinks}
                        >
                          {intl.formatMessage({id: "auth.back_to_login", defaultMessage: "Back to login"})}
                        </Link>
                      </CardActions>
                    </Card>
            </Grid>
        </>
    );
}

export default ForgotPassword;
